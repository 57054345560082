
let model = {}

model.dataForm = {
    id: undefined,
    title: undefined,
    file_id: undefined,
}
model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: false,
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sort: "title",
    defaultSort: ["title","ascending"],
    fields: "id,title,file_id",
    dataSearch: {
        value: {
            title: ""
        },
        operator: {
            title: ":regex_i:",
        },
    }
}

export default model